<template>
  <a-modal :visible="visible" title="导入员工" @ok="handleOk" @cancel="cancel">
    <slot></slot>
    <aUpload
      name="file"
      :multiple="multiple"
      :before-upload="beforeUpload"
      :fileList="fileList"
      @change="handleChange"
    >
      <a-button>{{text}}</a-button>
    </aUpload>
  </a-modal>
</template>

<script>
import { ref } from "vue";
import aUpload from "ant-design-vue/lib/upload";
import { message } from "ant-design-vue";
export default {
  components: { aUpload },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    text:{
      type:String,
      default:'选择表格'
    }
  },
  setup(prop, context) {
    const fileList = ref([]);
    const fileExtensions = ["xls", "xlsx"];

    function handleOk() {
      if (fileList.value.length) {
        context.emit("success", fileList.value[0]);
      } else {
        message.warning("请选择文件");
      }
    }

    function cancel() {
      context.emit("cancel");
    }

    function beforeUpload(e) {
      console.log("beforeUpload", e);
      console.log(fileList.value);
      return false;
    }

    function handleChange(e) {
      const { file, fileList:list} = e;
      const fileExtension = file.name
        .toLowerCase()
        .split(".")
        .pop();
      if (!fileExtensions.includes(fileExtension)) {
        return message.error(`文件类型错误`);
      }
      if(list.length){
        fileList.value[0] = file;
      } else {
        fileList.value = []
      }
        fileList.value = []
        e.fileList = []
      console.log("handleChange", e);
    }

    return {
      fileList,
      handleOk,
      beforeUpload,
      handleChange,
      cancel
    };
  }
};
</script>

<style>
</style>