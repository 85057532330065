<template>
  <div class="uploadStaff">
    <myUpLoad :visible="show" @cancel="cancel" @success="success">
      <a-button style="margin-bottom:20px" @click="getTemplat">下载模板</a-button>
    </myUpLoad>
  </div>
</template>

<script>
import myUpLoad from "@/components/myUpLoad.vue";
import { exportShopStaffTemplate, exportShopStaff } from "@/utils/api";
import { downloadBobl } from "@/utils/util";
import { message } from "ant-design-vue";
export default {
  components: { myUpLoad },
  props: {
    shopId: {
      type: String
    },
    show:{
        type:Boolean
    }
  },
  setup(prop, context) {

    async function getTemplat() {
      const res = await exportShopStaffTemplate();
      console.log(res);
      downloadBobl(res, "员工导入表格模板");
    }

    function cancel() {
      context.emit('update:show', false)
    }

    async function upload(data) {
      const res = await exportShopStaff(data);
      if (res.code == 0) {
        context.emit('update:show', false)
        message.success("操作成功");
      }
    }

    function success(e) {
      const form = new FormData();
      form.append("file", e);
      form.append("shopId", prop.shopId);
      upload(form);
    }

    return {
      cancel,
      success,
      getTemplat
    };
  }
};
</script>

<style>
</style>